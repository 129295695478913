export const sqrCategoryBg = [
  {
    name: '土地变更',
    code: 'N300103',
    bg: [
      {
        category2: '2',
      },
    ],
    bz: '根据不动产权证书号获取验证，获取的权利人义务人category为2,3, 权利人不变',
  },
  {
    name: '土地转移',
    code: 'N200103',
    bg: [
      {
        category2: '3',
      },
    ],
    bz: '根据不动产权证书号获取验证，获取的权利人义务人category为2,3, 上笔权利人变为这笔义务人',
  },
  {
    name: '土地注销',
    code: 'N400103',
    bg: [
      {
        category2: '2',
      },
    ],
    bz: '根据不动产权证书号获取验证，获取的权利人义务人category为2,3, 权利人不变',
  },
  {
    name: '房屋转移',
    code: 'N200104',
    bg: [
      {
        category0: '1',
        category1: '1',
      },
    ],
    bz: '根据不动产权证书号获取验证，获取的权利人义务人category为0,1, 上笔权利人变为这笔义务人',
  },
  {
    name: '房屋变更',
    code: 'N300104',
    bg: [
      {
        category0: '1',
      },
    ],
    bz: '根据不动产权证书号获取验证，获取的权利人义务人category为0,1, 权利人不变',
  },
  {
    name: '房屋注销',
    code: 'N400104',
    bg: [
      {
        category0: '0',
      },
    ],
    bz: '根据不动产权证书号获取验证，获取的权利人义务人category为0,1, 权利人不变',
  },
  {
    name: '房屋更正',
    code: 'N500104',
    bg: [
      {
        category0: '1',
      },
    ],
    bz: '根据不动产权证书号获取验证，获取的权利人义务人category为0,1, 权利人不变',
  },
  {
    name: '预售商品房买卖预告登记',
    code: 'N100201',
    bg: [
      {
        category7: '7',
        category8: '8',
      },
    ],
    bz: '根据合同号获取验证，获取的category为7,8, 权利人义务人不变',
  },
  {
    name: '预售商品房买卖预告登记',
    code: 'N100211',
    bg: [
      {
        category7: '7',
        category8: '8',
      },
    ],
    bz: '根据合同号获取验证，获取的category为7,8, 权利人义务人不变',
  },
  {
    name: '预售商品房抵押权预告',
    code: 'N100203',
    bg: [
      {
        category7: '10',
        category9: '9',
        category10: '10',
        category11: '11',
      },
    ],
    bz: '根据预告证明号获取验证，获取的权利人义务人category为7,8, 上笔权利人变为这笔义务人',
  },
  {
    name: '预售商品房买卖预告注销登记',
    code: 'N360000',
    bg: [
      {
        category7: '7',
        category8: '8',
      },
    ],
    bz: '根据预告证明号获取验证，获取的权利人义务人category为7,8, 权利人义务人不变',
  },
  {
    name: '预售商品房抵押权预告注销登记',
    code: 'N300126',
    bg: [
      {
        category4: '9',
        category9: '9',
        category10: '10',
        category11: '11',
      },
    ],
    bz: '根据抵押证明号获取验证，获取的权利人义务人category为9,10, 权利人义务人不变',
  },
  {
    name: '一般抵押权首次',
    code: 'N100301',
    bg: [
      {
        category0: '5',
        category5: '5',
        category6: '6',
      },
    ],
    bz: '根据不动产权证书号获取验证，获取的权利人义务人category为0,1, 上笔权利人变为这笔义务人',
  },
  {
    name: '抵押权注销',
    code: 'N400301',
    bg: [
      {
        category4: '4',
        category5: '5',
        category6: '6',
      },
    ],
    bz: '根据抵押证明号获取验证，获取的权利人义务人category为4,5,6, 权利人义务人不变',
  },
  {
    name: '证书换证登记',
    code: 'N900102',
    bg: [
      {
        category0: '0',
      },
    ],
    bz: '根据不动产权证书号获取验证，获取的权利人义务人category为0,1, 权利人不变',
  },
  {
    name: '证书补证登记',
    code: 'N900101',
    bg: [
      {
        category0: '0',
      },
    ],
    bz: '根据不动产权证书号获取验证，获取的权利人义务人category为0,1, 权利人不变',
  },
  {
    name: '土地抵押首次',
    code: 'N200111',
    bg: [
      {
        category2: '5',
      },
    ],
    bz: '根据不动产权证书号获取验证，获取的权利人义务人category为2,3, 上笔权利人变为这笔义务人',
  },
  {
    name: '土地抵押转移',
    code: 'N200112',
    bg: [
      {
        category2: '5',
      },
    ],
    bz: '根据不动产权证书号获取验证，获取的权利人义务人category为2,3, 上笔权利人变为这笔义务人',
  },
  {
    name: '土地抵押注销',
    code: 'N200112',
    bg: [
      {
        category4: '4',
        category5: '5',
        category6: '6',
      },
    ],
    bz: '根据抵押证明号获取验证，获取的权利人义务人category为4,5,6, 权利人义务人不变',
  },
  {
    name: '在建工程首次抵押',
    code: 'N300099',
    bg: [
      {
        category0: '5',
      },
    ],
    bz: '根据不动产权证书号获取验证，获取的权利人义务人category为0,1, 上笔权利人变为这笔义务人',
  },
  {
    name: '在建工程抵押注销',
    code: 'N360099',
    bg: [
      {
        category4: '4',
        category5: '5',
        category6: '6',
      },
    ],
    bz: '根据抵押证明号获取验证，获取的权利人义务人category为4,5,6, 权利人义务人不变',
  },
  {
    name: '所有权转移登记+抵押权首次登记',
    code: 'H999002',
    bg: [
      {
        category0: '1',
      },
    ],
    bz: '根据不动产权证书号获取验证，获取的权利人义务人category为0,1, 上笔权利人变为这笔义务人',
  },
  {
    name: '预告登记+抵押预告登记',
    code: 'H999001',
    bg: [
      {
        category7: '7',
        category8: '8',
      },
    ],
    bz: '根据合同号获取验证，获取的权利人义务人category为7,8, 权利人义务人不变',
  },
  {
    name: '所有权变更登记+所有权转移登记',
    code: 'H999003',
    bg: [
      {
        category0: '1',
      },
    ],
    bz: '根据不动产权证书号获取验证，获取的权利人义务人category为0,1, 上笔权利人变为这笔义务人',
  },
  {
    name: '宅基地所有权及房屋所有权转移登记',
    code: 'N200106',
    bg: [
      {
        category0: '1',
        category1: '1',
      },
    ],
    bz: '根据不动产权证书号获取验证，获取的权利人义务人category为0,1, 上笔权利人变为这笔义务人',
  },
  {
    name: '宅基地所有权及房屋所有权变更登记',
    code: 'N300106',
    bg: [
      {
        category0: '1',
        category1: '1',
      },
    ],
    bz: '根据不动产权证书号获取验证，获取的权利人义务人category为0,1, 上笔权利人变为这笔义务人',
  },
  {
    name: '宅基地所有权及房屋所有权更正登记',
    code: 'N500106',
    bg: [
      {
        category0: '1',
        category1: '1',
      },
    ],
    bz: '根据不动产权证书号获取验证，获取的权利人义务人category为0,1, 上笔权利人变为这笔义务人',
  },
  {
    name: '宅基地所有权及房屋所有权注销登记',
    code: 'N400106',
    bg: [
      {
        category0: '0',
      },
    ],
    bz: '根据不动产权证书号获取验证，获取的权利人义务人category为0,1, 权利人不变',
  },
  {
    name: '宅基地所有权及房屋所有权补证登记',
    code: 'N600106',
    bg: [
      {
        category0: '0',
      },
    ],
    bz: '根据不动产权证书号获取验证，获取的权利人义务人category为0,1, 权利人不变',
  },
  {
    name: '宅基地所有权及房屋所有权补证登记',
    code: 'N700106',
    bg: [
      {
        category0: '0',
      },
    ],
    bz: '根据不动产权证书号获取验证，获取的权利人义务人category为0,1, 权利人不变',
  },
  {
    name: '所有权转移登记+抵押权首次登记(组合贷一审)',
    code: 'H100311',
    bg: [
      {
        category0: '5',
        category5: '5',
        category6: '6',
      },
    ],
    bz: '根据不动产权证书号获取验证，获取的权利人义务人category为0,1, 上笔权利人变为这笔义务人',
  },
];
