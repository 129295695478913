import request from '@/pages/iebdc/utils/request';
import { REST_SERVICE } from '@/pages/iebdc/config';
const applicantRightService = {
  // 根据不动产权证编号获取不动产信息
  checkBdcqxxByZh(data) {
    return request({
      url: REST_SERVICE.rightCertify.checkBdcqxxByZh,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      data,
    });
  },

  savePrVerificationInfo(data) {
    return request({
      url: REST_SERVICE.rightCertify.savePrVerificationInfo,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      data,
    });
  },
  // 房屋类型根据不动产权证编号获取不动产信息
  getBdcxxByBdcqzh(data) {
    return request({
      url: REST_SERVICE.rightCertify.getBdcxxByBdcqzh,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      data,
    });
  },
  // 土地类型根据不动产权证编号获取不动产信息
  getTdBdcxxByBdcqzh(data) {
    return request({
      url: REST_SERVICE.rightCertify.getTdBdcxxByBdcqzh,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      data,
    });
  },
  // 根据抵押证明号获取不动产信息
  getBdcxxByDyzmh(data) {
    return request({
      url: REST_SERVICE.rightCertify.getBdcxxByDyzmh,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      data,
    });
  },
  // 预告证明号提取信息
  getBdcxxByYgzmh(data) {
    return request({
      url: REST_SERVICE.rightCertify.getBdcxxByYgzmh,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      data,
    });
  },
  // 根据不动产单元号获取不动产信息
  getBdcxxByDyh(params) {
    return request({
      url: REST_SERVICE.rightCertify.getBdcxxByDyh,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      data: params,
    });
  },
  // 根据合同编号获取不动产信息
  getBdcxxByHtbh(data) {
    return request({
      url: REST_SERVICE.rightCertify.getBdcxxByHtbh,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      data,
    });
  },
  // 根据业务号获取不动产信息
  getBdcxxByYwh(data) {
    return request({
      url: REST_SERVICE.rightCertify.getBdcxxByYwh,
      method: 'post',
      data,
    });
  },
  // 根据不动产权证编号获取不动产信息
  getYgxxByYwh(data) {
    return request({
      url: REST_SERVICE.rightCertify.getYgxxByYwh,
      method: 'post',
      data,
    });
  },
};

export default applicantRightService;
