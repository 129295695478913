<template>
  <div class="main box-shadow-default">
    <div class="wrap topWrap">
      <!-- <div class="title">
        不动产权信息
        <p class="redPoint">温馨小提示:</p>
        <p class="redPoint">1、产权核验用于核验当前房屋是否具备相应登记条件；</p>
        <p class="redPoint">2、产权核验通过后，会自动提取房屋相关信息；</p>
        <p class="redPoint">
          3、如未提取到不动产信息，建议前往属地不动产登记中心窗口进行业务申请，如确认不动产权证书合法有效，您也可以点击“跳过”按钮，自行填写信息；
        </p>
        <div class="btnWrap" @click="showMore = !showMore">
          <i :class="showMore ? 'el-icon-top' : 'el-icon-bottom'"></i>
        </div>
      </div> -->
      <div class="tip-title warpTips">
        <h4 style="min-width: 90px">温馨提示：</h4>
        <div class="right-box">
          <div>
            <div>
              <p>1、产权核验用于核验当前房屋是否具备相应登记条件；</p>
              <p>2、产权核验通过后，会自动提取房屋相关信息；</p>
              <p>
                3、如未提取到不动产信息，建议前往属地不动产登记中心窗口进行业务申请，如确认不动产权证书合法有效，您也可以点击“跳过”按钮，自行填写信息；
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="bgnColor" v-if="showMore">
        <el-form ref="cqhyForm" :model="propertyData" label-width="auto" v-if="mbanCode !== '4'">
          <el-row v-if="isJJJG">
            <el-col :span="12">
              <el-form-item label="义务人名称" prop="ywrmc" :rules="[rules.required]">
                <el-input v-model="propertyData.ywrmc" placeholder="请填写义务人名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="义务人证件号" prop="ywrzjh" :rules="[rules.required]">
                <el-input v-model="propertyData.ywrzjh" placeholder="填写义务人证件号"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item :label="mbanName" prop="bdcqzh" :rules="[rules.required]">
            <choose-bdcqzh
              v-model="propertyData.bdcqzh"
              :bdcqzhConfig="bdcqzhConfig"
              @update:bdcqzhData="(val) => (propertyData.bdcqzh = val)"
            ></choose-bdcqzh>
          </el-form-item>
          <el-row
            v-for="(bdcdyh, bdcdyhIndex) in propertyData.bdcdyh"
            :key="bdcdyhIndex"
            type="flex"
            justify="space-between"
          >
            <el-col :span="20">
              <el-form-item class="dyhWrap" label="不动产单元号" :prop="bdcdyh" :rules="[rules.estateUnitNo]">
                <el-input v-model="propertyData.bdcdyh[bdcdyhIndex]" placeholder="填写不动产单元号"></el-input>
              </el-form-item>
            </el-col>
            <!-- <div class="width160">
              <el-button type="primary" @click="addBdcDyh()">添加</el-button>
              <el-button type="primary" v-if="propertyData.bdcdyh.length > 1" @click="delBdcDyh(bdcdyhIndex)">删除</el-button>
            </div> -->
          </el-row>
          <el-row>
            <!-- <el-col :span="20">
              <el-form-item label="不动产坐落" prop="zlData.zlArea" :rules="[rules.required]">
                <zl-directives v-model="propertyData.zlData" :zlConfig="zlConfig"></zl-directives>
              </el-form-item>
            </el-col> -->
            <el-col :span="4" class="flex-center-align" style="justify-content: flex-end">
              <el-button type="primary" @click="searchNext">查询</el-button>
            </el-col>
          </el-row>
          <el-form-item class="searchBtn">
            <span>{{ checkResult }}</span>
          </el-form-item>
        </el-form>
        <el-form v-else ref="dyhForm" :model="mergePropertyData" label-width="130px">
          <el-row
            v-for="(bdcdyh, bdcdyhIndex) in mergePropertyData.bdcdyh"
            :key="bdcdyhIndex"
            type="flex"
            justify="space-between"
          >
            <el-col :span="20">
              <el-form-item class="dyhWrap" label="不动产单元号" :prop="bdcdyh" :rules="[rules.required]">
                <el-input v-model="mergePropertyData.bdcdyh[bdcdyhIndex]" placeholder="填写不动产单元号"></el-input>
              </el-form-item>
            </el-col>
            <div class="width160">
              <el-button type="primary" @click="addBdcDyh">添加</el-button>
              <el-button type="primary" v-if="mergePropertyData.bdcdyh.length > 1" @click="delBdcDyh(bdcdyhIndex)"
                >删除</el-button
              >
            </div>
          </el-row>
          <el-row>
            <!-- <el-col :span="20">
              <el-form-item label="不动产坐落" prop="zlData.zlArea" :rules="[rules.required]">
                <zl-directives v-model="mergePropertyData.zlData" :zlConfig="zlConfig"></zl-directives>
              </el-form-item>
            </el-col> -->
            <el-col :span="4" class="flex-center-align" style="justify-content: flex-end">
              <el-button type="primary" @click="searchNext">查询</el-button>
            </el-col>
          </el-row>
          <el-form-item class="searchBtn">
            <span>{{ checkResult }}</span>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="wrap" v-if="$hasConstruction('IEBDC:CQZHY:XSQLRLB')" v-show="participant.length > 0">
      <div class="title">权利人</div>
      <e-table ref="myPersonRef" :tableList="participant" :columns="personColumns" :options="personOptions"></e-table>
    </div>
    <div class="wrap" v-show="bdcList && bdcList.length > 0">
      <div class="title">
        不动产信息
        <span class="redPoint">温馨小提示：勾选要办理的不动产信息才能办理下一步哟</span>
      </div>
      <e-table
        ref="myBdcRef"
        :tableList="bdcList"
        :columns="mbanCode === '4' ? mergeColumns : columns"
        :operates="operates"
        :options="options"
        @selectionChangeHandle="selectionChangeHandle"
      ></e-table>
    </div>
    <div class="main-footer text-center">
      <el-button type="primary" @click="prev">上一步</el-button>
      <el-button type="primary" @click="passCertify" v-if="!isJJJG">跳过</el-button>
      <el-button type="primary" v-show="bdcList && bdcList.length > 0" @click="hyPass">下一步</el-button>
    </div>
    <e-dialog :config="openRightInfo">
      <template slot="content">
        <table class="table">
          <tr>
            <td class="labelTip">不动产单元号</td>
            <td colspan="3">{{ bdcxx.bdcdyh }}</td>
          </tr>
          <tr v-if="mbanCode !== '4'">
            <td class="labelTip">不动产权证号</td>
            <td colspan="3">{{ bdcxx.bdcqzh }}</td>
          </tr>
          <!-- <tr>
            <td class="labelTip">区域名称</td>
            <td>{{ bdcxx.qydm | filterAreaByCode(data) }}</td>
            <td class="labelTip">登簿时间</td>
            <td>{{ bdcxx.dbsj }}</td>
          </tr> -->
          <tr>
            <td class="labelTip">坐落</td>
            <td colspan="3">{{ bdcxx.zl }}</td>
          </tr>
          <tr>
            <td class="labelTip">不动产面积(㎡)</td>
            <td>{{ bdcxx.jzmj }}</td>
            <td class="labelTip">房屋交易价格(万元)</td>
            <td>{{ bdcxx.fwjyjg }}</td>
          </tr>
          <tr>
            <td class="labelTip">项目名称</td>
            <td>{{ bdcxx.xmmc }}</td>
            <td class="labelTip">幢号</td>
            <td>{{ bdcxx.zh }}</td>
          </tr>
          <tr>
            <td class="labelTip">室号部位</td>
            <td>{{ bdcxx.shbw }}</td>
            <td class="labelTip">建筑面积</td>
            <td>{{ bdcxx.jzmj }}</td>
          </tr>
          <tr>
            <td class="labelTip">套内建筑面积</td>
            <td>{{ bdcxx.tnjzmj }}</td>
            <td class="labelTip">分摊建筑面积</td>
            <td>{{ bdcxx.ftjzmj }}</td>
          </tr>
          <tr>
            <td class="labelTip">房屋用途</td>
            <td>
              <dict-span type="房屋用途" :binddata="bdcxx.fwyt"></dict-span>
            </td>
            <td class="labelTip">竣工时间</td>
            <td>{{ myFormatDate(bdcxx.jgsj) }}</td>
          </tr>
          <tr>
            <td class="labelTip">是否预告</td>
            <td>
              <dict-span type="是否" :binddata="bdcxx.sfyg"></dict-span>
            </td>
            <td class="labelTip">是否预告抵押</td>
            <td>
              <dict-span type="是否" :binddata="bdcxx.sfygdy"></dict-span>
            </td>
          </tr>
          <tr>
            <td class="labelTip">是否抵押</td>
            <td>
              <dict-span type="是否" :binddata="bdcxx.sfdy"></dict-span>
            </td>
            <td class="labelTip">是否异役</td>
            <td>
              <dict-span type="是否" :binddata="bdcxx.sfyy"></dict-span>
            </td>
          </tr>
          <tr>
            <td class="labelTip">是否地役</td>
            <td>
              <dict-span type="是否" :binddata="bdcxx.sfdyi"></dict-span>
            </td>
            <td class="labelTip">是否查封</td>
            <td>
              <dict-span type="是否" :binddata="bdcxx.sfcf"></dict-span>
            </td>
          </tr>
          <tr>
            <td class="labelTip">证书附记备注</td>
            <td colspan="3">{{ bdcxx.bz }}</td>
          </tr>
        </table>
      </template>
    </e-dialog>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import { getCookie } from '@iebdc/utils/cookies';
import { localDataUser } from '@iebdc/utils/local-data';
import { bdcMban } from '@public/bdcMban.js';
import { sqrCategoryBg } from '@public/sqrCategoryBg.js';
import applicantRightService from '@/pages/iebdc/api/apply/applicant-right';
import applicantSaveService from '@/pages/iebdc/api/apply/applicant-save';
import applicantOperatorService from '@/pages/iebdc/api/apply/applicant-operator';
import rules from '@/utils/validate.js';
import { Loading } from 'element-ui';
import { formatDate, filterAreaByCode } from '@/filters/index';
import { area } from '@/pages/iebdc/api/uums';
import { sameSqr } from '@public/applyConfig/sameSqr';
export default {
  name: 'rightCertify',
  computed: {
    // 下一步跳转所需数据
    ...mapState('wysq-step', ['nextItem', 'prevItem']),
    ...mapState('wysq-data', ['flowInfoIng']),
    // 选择的流程类型信息
    ...mapState('wysq-data', ['firstFlow']),
    ...mapState('wysq-step', ['activeIndex', 'currentSelecte']),
    ...mapState('taxStatus', ['taxStatus', 'beianStatus']),
    // 选择的机构信息
    getSelectedOrgInfo() {
      return this.$store.getters['dashboard/getSelectedOrgInfo'];
    },
    isJJJG() {
      return localDataUser.get().userCategory === 'JJJG';
    },
  },
  async beforeCreate() {
    const { data } = await area();
    this.data = data;
  },
  data() {
    return {
      data: null,
      checkResult: '', //产权校验返回结果显示
      rules: rules, // 校验规则
      loading: null,
      allAreaList: [], //所有区域列表
      bdcList: [],
      participant: [],
      //弹窗配置项
      openRightInfo: {
        title: '查看产权详情',
        visible: false,
        hasConfirm: false,
        hasCancel: false,
        hasBtn: false,
      },
      bdcxx: {},
      showMore: true, //展开、收缩
      propertyData: {
        bdcdyh: [''],
        bdcqzh: '',
        qlrmc: '',
        zjh: '',
        qydm: '',
        lcdm: '',
        zlData: {
          zlProvince: getCookie('selectShengCode'),
          zlCity: getCookie('selectedShiCode'),
          zlArea: getCookie('selectedAreaCode'),
        },
      },
      mergePropertyData: {
        bdcdyh: [''],
        qydm: '',
        lcdm: '',
        zlData: {
          zlProvince: getCookie('selectShengCode'),
          zlCity: getCookie('selectedShiCode'),
          zlArea: getCookie('selectedAreaCode'),
        },
      },
      mergeColumns: [
        {
          width: '150px',
          prop: 'bdcdyh',
          label: '不动产单元号',
        },
        {
          width: '150px',
          prop: 'zl',
          label: '坐落',
        },
        {
          width: '90px',
          prop: 'xzzt',
          label: '限制状态',
        },
        {
          // width: '60px',
          prop: 'xmmc',
          label: '项目名称',
        },
        {
          // width: '60px',
          prop: 'zh',
          label: '幢号',
        },
        {
          // width: '60px',
          prop: 'shbw',
          label: '室号部位',
        },
      ],
      huData: {}, //H999001流程不动产信息
      mbanName: '',
      mbanCode: '',
      bdcqzhConfig: {
        rules: [rules.required],
        mbanType: '',
        disabled: false,
      },
      zlConfig: {
        zlKey: 'code',
        rules: [],
        hideZl: true,
        disabled: false,
      },
      personColumns: [
        {
          width: '200px',
          prop: 'name',
          label: '权利人名称',
        },
        {
          width: '180px',
          prop: 'zjzl',
          label: '证件种类',
          type: 'dic',
        },
        {
          width: '200px',
          prop: 'zjh',
          label: '证件号码',
        },
        {
          width: '150px',
          prop: 'phone',
          label: '联系电话',
        },
        {
          width: '150px',
          prop: 'gyfs',
          label: '共有方式',
          type: 'dic',
        },
        {
          width: '150px',
          prop: 'qlbl',
          label: '权利比例',
        },
      ],
      personOptions: {
        // 每页数据数
        pageSize: 1000,
        // 当前页码
        currentPage: 1,
        loading: true,
        hasPagination: false,
      },
      columns: [
        {
          width: '150px',
          prop: 'bdcqzh',
          label: '不动产权证号',
          show: false,
        },
        {
          width: '150px',
          prop: 'bdcdyh',
          label: '不动产单元号',
        },
        {
          width: '150px',
          prop: 'zl',
          label: '坐落',
        },
        {
          width: '70px',
          prop: 'xzzt',
          label: '限制状态',
          formatter: (row) => {
            return this.getXzztHtml(row);
          },
        },
        {
          width: '90px',
          prop: 'xmmc',
          label: '项目名称',
        },
        {
          width: '60px',
          prop: 'zh',
          label: '幢号',
        },
        {
          width: '70px',
          prop: 'shbw',
          label: '室号部位',
        },
      ],
      operates: {
        //表格操作栏
        width: '80px',
        // fixed: 'right',
        list: [
          {
            show: true,
            spanType: 'button',
            id: '1',
            label: '查看',
            type: 'success',
            method: (key, row) => {
              this.openRightInfo.visible = true;
              this.bdcxx = row;
            },
            showCallback: () => {
              return true;
            },
          },
        ],
      },
      options: {
        // 每页数据数
        pageSize: 1000,
        // 当前页码
        currentPage: 1,
        loading: true,
        mutiSelect: true,
        hasIndex: true,
        hasPagination: false,
        showTip: false,
        // marginTop: '10px',
        // height: '420px',
        // maxHeight: '300px'
      },
      slectedArr: [], //不动产信息选中数据
      cqhyData: {},
      bdcdjzmh: '', //不动产登记证明号
      sameConfig: '', //同一申请人
    };
  },
  created() {
    this.propertyData.lcdm = this.firstFlow.thirdTypeData.netFlowdefCode;
    //1,找出对应模板 2,根据***分割模板插入input
    this.getMbanConfig();
    //获取所有区域列表
    this.allAreaList = this.getAllNodes(this.$store.getters.getArea);
    //初始化相同申请人
    sameSqr.forEach((data) => {
      if (data.code === this.firstFlow.thirdTypeData.netFlowdefCode) {
        this.sameConfig = data.same;
      }
    });
  },
  methods: {
    ...mapMutations('wysq-step', ['targetToStep']),
    ...mapState('area', ['area']),
    //找出对应模板配置
    getMbanConfig() {
      bdcMban.forEach((item) => {
        if (item.code.indexOf(this.propertyData.lcdm) > -1) {
          this.bdcqzhConfig.mbanType = item.mbanType;
          this.mbanName = item.mbanName;
          this.mbanCode = item.mbanCode;
        }
      });
    },
    startLoading(text) {
      this.loading = Loading.service({
        lock: true,
        text, //可以自定义文字
        spinner: 'el-icon-loading', //自定义加载图标类名
        background: 'rgba(0, 0, 0, 0.7)', //遮罩层背景色
        target: '.topWrap', // 需要遮罩的区域
        customClass: 'mask',
      });
    },
    endLoading() {
      this.loading.close();
    },
    //添加不动产单元号
    addBdcDyh() {
      if (this.mbanCode === '4') {
        this.mergePropertyData.bdcdyh.push('');
      } else {
        this.propertyData.bdcdyh.push('');
      }
    },
    //删除不动产单元号
    delBdcDyh(index) {
      if (this.mbanCode === '4') {
        this.mergePropertyData.bdcdyh.splice(index, 1);
      } else {
        this.propertyData.bdcdyh.splice(index, 1);
      }
    },
    // 选择
    selectionChangeHandle(val) {
      this.slectedArr = val;
    },
    async savePr(params) {
      const params1 = {
        taxStatus: this.taxStatus,
        beianStatus: this.beianStatus,
        sfyddbl: 0,
        qlxz: '',
        lcdm: this.firstFlow.thirdTypeData.netFlowdefCode,
        userName: localDataUser.get().realName,
        userId: localDataUser.get().id, // 用户id
        sqrlx: localDataUser.get().userCategory, // 申请人类型
        bdclb: this.firstFlow.secondTypeData.bdclbEnum, // 不动产类别
        flowcode: this.firstFlow.thirdTypeData.flowCode,
        netFlowdefCode: this.firstFlow.thirdTypeData.netFlowdefCode,
        flowname: this.firstFlow.thirdTypeData.name,
        djdl: this.firstFlow.thirdTypeData.djdl,
        qllx: this.firstFlow.thirdTypeData.qllx,
        subFlowcode: this.firstFlow.fourTypeData.code,
        subFlowname: this.firstFlow.fourTypeData.name,
        taskCode: this.firstFlow.thirdTypeData.taskCode,
        taskHandleItem: this.firstFlow.thirdTypeData.taskHandleItem,
        pjlcmc: this.firstFlow.thirdTypeData.pjlcmc,
        qlxxEx: this.mbanCode === '4' ? this.huData.qlxxEx : this.cqhyData.qlxxEx,
        qlxxExMhs: this.mbanCode === '4' ? this.huData.qlxxExMhs : this.cqhyData.qlxxExMhs,
        qlxxExMhList: [],
        ...this.getSelectedOrgInfo,
      };
      const { data, success, message } = await applicantSaveService.saveSqxx(params1);
      if (success) {
        params.bdcdyhs = params.params[0].bdcdyh.join('');
        params.params[0].ywh = data.wwywh;
        applicantRightService
          .savePrVerificationInfo(Object.assign(params.params[0], { bdcdyhs: params.bdcdyhs }))
          .then((res1) => {
            if (res1.success) {
              res1.data.lcdm = this.firstFlow.thirdTypeData.netFlowdefCode;
              res1.data.qydm = res1.data.zlArea;
              res1.data.zhlx = this.mbanCode;
              res1.data.bdclx = this.firstFlow.secondTypeData.bdclbEnum;
              var paramsCheck = {};
              paramsCheck.params = [];
              paramsCheck.params.push(res1.data);
              paramsCheck.ywh = data.wwywh;
              paramsCheck.hybh = res1.data.hybh;
              this.checkBdcqxxByZh(paramsCheck);
            }
          });
      } else {
        this.$message.error(message);
      }
    },
    getBdcxxByBdcqzh() {},
    //产权证核验
    async checkBdcqxxByZh(params) {
      this.startLoading('正在进行流程校验...');
      // (params.params || []).forEach(item => {
      //   item.qydm = this.getCodeByAreaName(item.qydm);
      // });
      await applicantRightService
        .checkBdcqxxByZh(params)
        .then((res) => {
          this.endLoading();
          if (res.success) {
            if (res.data) {
              this.checkResult = '';
              this.getInfoByZh(params);
            } else {
              this.checkResult = res.message;
              this.$message.warning(res.message);
            }
          } else {
            // this.checkResult = res.message;
            this.$message.warning('暂未查询到数据！');
          }
        })
        .catch((err) => {
          this.endLoading();
          this.$message.warning('查询异常！');
          // this.checkResult = err.message;
        });
    },
    //查询
    searchNext() {
      // 初始查询结果
      this.bdcList = [];
      this.participant = [];
      if (this.mbanCode === '4') {
        this.$refs.dyhForm.validate((valid) => {
          if (valid) {
            // 根据单元号提取不动产
            this.getBdcDataByDyh();
          }
        });
      } else {
        // 需要产权核验
        this.$refs.cqhyForm.validate((valid) => {
          if (valid) {
            let { zlData, ...rest } = this.propertyData;
            let propertyData = { ...rest, ...zlData };
            console.log(getCookie('selectedAreaCode'));
            propertyData.qydm = getCookie('selectedAreaCode');
            propertyData.bdcqzh = propertyData.bdcqzh.split(',') || [];
            let params = [];
            params.push(propertyData);
            this.savePr({ params });
            // this.checkBdcqxxByZh({ params });
          }
        });
      }
    },
    getBdcDataByDyh() {
      this.startLoading('查询数据中...');
      const params = {
        bdcdyh: this.mergePropertyData.bdcdyh,
        bdclx: '',
        lcdm: this.firstFlow.thirdTypeData.netFlowdefCode,
        qydm: getCookie('selectedAreaCode'),
        zhlx: '',
      };
      const a = [];
      a.push(params);
      applicantRightService
        .getBdcxxByDyh({ params: a })
        .then((res) => {
          this.endLoading();
          if (res.success && res.data) {
            //获取不动产信息 （根据证号查出的不动产信息， 申请三级流程名称)
            if (res.data.bdcxx) {
              this.bdcList = res.data.bdcxx;
              this.setXzzt(this.bdcList);
            } else if (Array.isArray(res.data) && res.data.length > 0 && res.data[0].bdcxx) {
              this.bdcList = res.data[0].bdcxx;
              this.setXzzt(this.bdcList);
            } else {
              this.$message.error('未查询到相关不动产信息');
            }
          }
        })
        .catch((err) => {
          this.endLoading();
        });
    },
    // 上一步
    prev() {
      this.$router.push({
        name: this.prevItem.name,
        query: 1,
      });
    },
    //下一步 保存申请信息
    hyPass() {
      if (this.slectedArr.length <= 0) {
        this.$message.error('请勾选需要办理的不动产信息');
        return;
      }
      if (this.mbanCode === '4') {
        this.huData.qlxxExMhs = this.getqlxxExMhs(this.slectedArr);
        // 此处不添加会导致【预售商品房买卖预告登记】中不动产信息区域为空
        this.huData.qlxxExMhs[0].zlCity = getCookie('selectedShiTitle');
        this.huData.qlxxExMhs[0].zlArea = getCookie('selectedAreaTitle');
        console.log('this.huData.qlxxExMhs', this.huData.qlxxExMhs)
        this.huData.qlxxEx = {
          sqdjyy: this.firstFlow.fourTypeData.name,
          sffbcz: false,
          sfdbz: false,
          sqzsbs: '0',
        };
      } else {
        this.cqhyData.qlxxExMhs = this.getqlxxExMhs(this.slectedArr);
        console.log(getCookie('selectedShengTitle'));
        console.log(getCookie('selectedShiTitle'));
        console.log(getCookie('selectedAreaTitle'));
        console.log(this.propertyData.zlCity);
        console.log(this.propertyData.zlData);
        console.log('cqhyData.qlxxExMhs===' + this.cqhyData.qlxxExMhs);
        this.cqhyData.qlxxExMhs.map((item) => {
          const updataData = {
            //保存坐落信息
            zlProvince: getCookie('selectedShengTitle'),
            zlCity: getCookie('selectedShiTitle'),
            zlArea: getCookie('selectedAreaTitle'),
            //保存不动产证明号信息
            bdcdjzmh: this.bdcdjzmh === '' ? '' : this.bdcdjzmh,
            jzmj: typeof item.jzmj === 'number' ? item.jzmj.toString() : item.jzmj,
            tnjzmj: typeof item.tnjzmj === 'number' ? item.tnjzmj.toString() : item.tnjzmj,
            ftjzmj: typeof item.ftjzmj === 'number' ? item.ftjzmj.toString() : item.ftjzmj,
          };
          Object.assign(item, updataData);
        });
      }
      console.log(this.cqhyData, this.mbanCode, 'this.cqhyData');
      this.saveFlowDefine(this.cqhyData);
    },
    //申请新业务
    async saveFlowDefine(cqhyData) {
      console.log(this.cqhyData)
      //抵押权注销登记中，抵押信息
      var qlxxEx = (this.cqhyData && this.cqhyData.qlxxEx && this.cqhyData.qlxxEx.length>0)? this.cqhyData.qlxxEx[0]: {}
      let bdbzzqse = ''
      if(this.cqhyData.qlxxExtra && this.cqhyData.qlxxExtra.length > 0){
        if(this.cqhyData.qlxxExtra.constructor === Array){
          bdbzzqse = this.cqhyData.qlxxExtra[0].bdbzzqse;
        } else {
          bdbzzqse = this.cqhyData.qlxxExtra.bdbzzqse;
        }
      }
      const params = {
        taxStatus: this.taxStatus,
        beianStatus: this.beianStatus,
        sfyddbl: 0,
        qlxz: '',
        bdcdjzmh: this.bdcdjzmh,
        userName: localDataUser.get().realName,
        lcdm: this.firstFlow.thirdTypeData.netFlowdefCode,
        userId: localDataUser.get().id, // 用户id
        sqrlx: localDataUser.get().userCategory, // 申请人类型
        bdclb: this.firstFlow.thirdTypeData.netFlowdefCode == 'F400200'? 'NYD' :this.firstFlow.secondTypeData.bdclbEnum, // 不动产类别
        flowcode: this.firstFlow.thirdTypeData.flowCode,
        netFlowdefCode: this.firstFlow.thirdTypeData.netFlowdefCode,
        flowname: this.firstFlow.thirdTypeData.name,
        djdl: this.firstFlow.thirdTypeData.djdl,
        qllx: this.firstFlow.thirdTypeData.qllx,
        subFlowcode: this.firstFlow.fourTypeData.code,
        subFlowname: this.firstFlow.fourTypeData.name,
        taskCode: this.firstFlow.thirdTypeData.taskCode,
        taskHandleItem: this.firstFlow.thirdTypeData.taskHandleItem,
        pjlcmc: this.firstFlow.thirdTypeData.pjlcmc,
        qlxxEx: this.mbanCode === '4' ? this.huData.qlxxEx: this.cqhyData.qlxxEx,
        qlxxExMhs: this.mbanCode === '4' ? this.huData.qlxxExMhs : this.cqhyData.qlxxExMhs,
        qlxxExMhList: [],
        ...this.getSelectedOrgInfo,
        ...qlxxEx,
        bdbzqse: bdbzzqse
      };
      //保存提取的业务信息生成新的业务号
      const { data, success, message } = await applicantSaveService.saveSqxx(params);
      if (success) {
        if (this.mbanCode === '4') {
          this.targetToStep('产权证核验');
          // 路由跳转
          this.$router.push({
            name: this.nextItem.name,
            params: {
              ywh: data.wwywh,
            },
          });
        } else {
          this.setDataCheck(cqhyData, data.wwywh, data.ywh);
        }
      } else {
        this.$message.error(message);
      }
    },
    //设置限制状态
    setXzzt(list) {
      list &&
        list.forEach((element) => {
          element.xzzt = '无';
          if (Number(element.sfyg)) {
            element.xzzt = '预告';
          } else if (Number(element.sfygdy)) {
            element.xzzt = '预告抵押';
          } else if (Number(element.sfdy)) {
            element.xzzt = '抵押';
          } else if (Number(element.sfyy)) {
            element.xzzt = '异议';
          } else if (Number(element.sfdyi)) {
            element.xzzt = '地异';
          } else if (Number(element.sfcf)) {
            element.xzzt = '查封';
          }
        });
    },
    //获取限制状态
    getXzztHtml(row) {
      let html = '';
      switch (row.xzzt) {
        case '预告':
          html = `<span style="color: #fff;padding: 10px;background-color: #A2A2DA;">预告</span>`;
          break;
        case '预告抵押':
          html = `<span style="color: #fff;padding: 10px;background-color: #f959ff;">预告抵押</span>`;
          break;
        case '抵押':
          html = `<span style="color: #fff;padding: 10px;background-color: #3EA1F4;">抵押</span>`;
          break;
        case '异议':
          html = `<span style="color: #fff;padding: 10px;background-color: #FFCC33;">异议</span>`;
          break;
        case '查封':
          html = `<span style="color: #fff;padding: 10px;background-color: #FF6666;">查封</span>`;
          break;
        default:
          html = `<span >${row.xzzt}</span>`;
          break;
      }
      return html;
    },
    // 处理产权核验数据
    async setDataCheck(data, wwywh, ywh) {
      console.log(data, wwywh, ywh, 'data');
      if (!data) return;
      // 不动产单元号
      const bdcdyhs = data.qlxxExMhs.reduce((acc, cur) => {
        acc.push(cur.bdcdyh);
        return acc;
      }, []);
      // 权利人
      let qlrList = [];
      // 义务人
      let ywrList = [];
      (data.participant || []).forEach((participant) => {
        participant.ywh = ywh[0];
        participant.dh = participant.phone;
        participant = this.categoryJudge(participant);
        //是否有对应的相同权利人义务人
        let sameSqr;
        if (
          this.firstFlow.thirdTypeData.netFlowdefCode === 'H100311' ||
          this.firstFlow.thirdTypeData.netFlowdefCode === 'H999025' ||
          this.firstFlow.thirdTypeData.netFlowdefCode === 'H100203'
        ) {
          sameSqr = this.getSameParticipantZhd(participant, ywh);
        } else {
          sameSqr = this.getSameParticipant(participant, ywh);
        }

        if (sameSqr) {
          if (sameSqr.qlrmc) {
            qlrList.push(sameSqr);
          }
          // 义务人信息集合
          if (sameSqr.ywrmc) {
            ywrList.push(sameSqr);
          }
        }
        // 权利人信息集合
        if (participant.qlrmc) {
          participant.qlrlx = participant.sqrlx 
          qlrList.push(participant);
        }
        // 义务人信息集合
        if (participant.ywrmc) {
           participant.ywrlx = participant.sqrlx 
          ywrList.push(participant);
        }
      });
      let qlrData = null;
      let ywrData = null;
      // 有权利人则发送权利人请求
      if (qlrList && qlrList.length) {
        if(this.firstFlow.thirdTypeData.netFlowdefCode == 'N100301'){
          var newQlr= qlrList.filter(item=> item.category !=='0');
          qlrList = newQlr;
        }
        qlrData = await applicantOperatorService.addRightHolderBatch({ bdcdyhs, qlrList });
        if (!qlrData.success) {
          console.log(qlrData.message);
        }
      }
      // 有义务人则发送义务人请求
      if (ywrList && ywrList.length) {
        if(this.firstFlow.thirdTypeData.netFlowdefCode == 'N100301'){
          var newYwr = ywrList.filter(item=> item.category !=='0');
          ywrList = newYwr;
        }
        ywrData = await applicantOperatorService.addObligorBatch({ bdcdyhs, ywrList });
        if (!ywrData.success) {
          console.log(ywrData.message);
        }
      }
      // 分开请求（并发请求后台会有事务方面的问题-不用，换分开单独请求）
      // 1、只调权利人 2、只调义务人 3、都调  （至少一个人）
      if (
        (qlrData && qlrData.success) ||
        (ywrData && ywrData.success) ||
        (qlrData && qlrData.success && ywrData && ywrData.success)
      ) {
        // 改变步骤条状态
        this.targetToStep('产权证核验');
        // 路由跳转
        this.$router.push({
          name: this.nextItem.name,
          params: {
            ywh: wwywh,
          },
        });
      } else {
        this.$message.error('权利人或义务人数据异常！')
      }
    },
    getSameParticipantZhd(sqr, ywh) {
      let sameSqr = '';

      //判断是否操作有相同的申请人,并取出另一个的category
      if (!this.sameConfig) return;
      if (this.sameConfig['form0'] === sqr.category) {
        console.log(this.propertyData.bdcqzh.split(',')[0] === sameSqr.bdcdjzmh);
        sameSqr = Object.assign({}, sqr);
        if (sameSqr.ywh && sameSqr.ywh.endsWith('-2')) {
          sameSqr.ywh = ywh[0];
        } else {
          sameSqr.ywh = ywh[1];
        }
        sameSqr.sqrType = sameSqr.sqrType === 'qlr' ? 'qlr' : 'ywr';
        sameSqr.category = this.sameConfig['form1'];
        if (sameSqr.qlrmc) {
          sameSqr.ywrmc = '';
        } else {
          sameSqr.qlrmc = '';
        }
      }
      return sameSqr;
    },
    //获取相同对应的人
    getSameParticipant(sqr, ywh) {
      let sameSqr = '';
      //判断是否操作有相同的申请人,并取出另一个的category
      if (!this.sameConfig) return;
      if (this.sameConfig['form0'] === sqr.category) {
        sameSqr = Object.assign({}, sqr);
        sameSqr.ywh = ywh[1];
        sameSqr.sqrType = sameSqr.sqrType === 'qlr' ? 'ywr' : 'qlr';
        sameSqr.category = this.sameConfig['form1'];
        if (sameSqr.qlrmc) {
          sameSqr.ywrmc = sameSqr.qlrmc;
          sameSqr.qlrmc = '';
        } else {
          sameSqr.qlrmc = sameSqr.ywrmc;
          sameSqr.ywrmc = '';
        }
      }
      return sameSqr;
    },
    //判断相关人员信息是权利人还是义务人
    categoryJudge(participant) {
      let qlrCategory = ['0', '2', '4', '7', '9', '12'];
      let ywrCategory = ['1', '3', '5', '6', '8', '10', '11'];
      let qlr = qlrCategory.find((category) => category === participant.category);
      let ywr = ywrCategory.find((category) => category === participant.category);
      //申请人为权利人时
      if (qlr) {
        // 信息不完整，需要组装
        participant.qlrmc = participant.name;
      } else if (ywr) {
        // 信息不完整，需要组装
        participant.ywrmc = participant.name;
      }
      return participant;
    },
    //整合数据提取不动产单元信息
    arrangeBdcxx(bdcList, zhlx) {
      let bdcdyhList = [];
      (bdcList || []).forEach((item) => {
        if (item.bdcdyh) {
          bdcdyhList.push(item.bdcdyh);
        }
      });
      return {
        bdcywh: bdcList[0].bdcywh,
        qydm: bdcList[0].qydm,
        bdcdyh: bdcdyhList.join(','),
        bdclx: bdcList[0].bdclx,
        zhlx,
      };
    },
    //根据不同的证号获取不动产信息
    async getInfoByZh(cqzhy) {
      let url = '';
      console.log('mbanCode', this.mbanCode);
      switch (this.mbanCode) {
        case '1':
          if (this.firstFlow.secondTypeData.bdclbEnum === 'HOUSE') {
            url = 'getBdcxxByBdcqzh';
          } else if (this.firstFlow.secondTypeData.bdclbEnum === 'LAND') {
            url = 'getTdBdcxxByBdcqzh';
          }
          break;
        case '2':
          //抵押证明号提取信息
          url = 'getBdcxxByDyzmh';
          this.changeKey(cqzhy.params, 'dyzmh');
          break;
        case '3':
          //预告证明号提取信息
          url = 'getBdcxxByYgzmh';
          this.changeKey(cqzhy.params, 'ygzmh');
          break;
        case '4':
          //不动产单元号提取信息
          url = 'getBdcxxByDyh';
          break;
        default:
          //合同号提取信息
          url = 'getBdcxxByHtbh';
          this.changeKey(cqzhy.params, 'htbh');
          break;
      }
      this.startLoading('查询数据中...');
      var temp = {};
      try {
        temp = await applicantRightService[url](cqzhy);
      } catch (e) {}
      const { success, data, message } = temp;
      this.endLoading();
      if (success && data) {
        let person = data[0].participants || [];
        console.log(person.toString());
        console.log(
          this.propertyData.ywrmc,
          this.propertyData.ywrzjh,
          person.toString().indexOf(this.propertyData.ywrmc) === -1
        );
        if (
          this.isJJJG &&
          person.toString().indexOf(this.propertyData.ywrmc) === -1 &&
          person.toString().indexOf(this.propertyData.ywrzjh) === -1
        ) {
          this.$message.warning('义务人信息错误，请重新填写后重试');
          return '';
        }
        console.log(data, this.cqhyData, '12');
        //获取不动产信息 （根据证号查出的不动产信息， 申请三级流程名称)
        this.bdcList = data[0].bdcxx;
        this.setXzzt(this.bdcList);
        this.participant = data[0].participants || []; //人员信息赋值

        this.cqhyData = {
          participant: data[0].participants,
          qlxxExMhs: data[0].bdcxx,
          qlxxExtra: data[0].qlxxExtra
        };
        if (data[0].qlxxExtra && Object.keys(data[0].qlxxExtra).length > 0) {
          const bdcdjzmh = cqzhy.params[0] && (cqzhy.params[0].dyzmh || cqzhy.params[0].ygzmh);
          this.bdcdjzmh = data[0].qlxxExtra.bdcdjzmh || (bdcdjzmh && bdcdjzmh.join(','));
          this.cqhyData.qlxxEx = this.arrangeqlxxEx(data[0], this.firstFlow.fourTypeData.name);
        } else {
          const bdcdjzmh = cqzhy.params[0] && (cqzhy.params[0].dyzmh || cqzhy.params[0].ygzmh);
          this.bdcdjzmh = bdcdjzmh && bdcdjzmh.join(',');
          this.cqhyData.qlxxEx = {
            sqdjyy: this.firstFlow.fourTypeData.name,
            sffbcz: false,
            sfdbz: false,
            sqzsbs: '0',
          };
        }
        //相关人员信息展示去重
        this.cqhyData.participant = this.uniqueSqrxx(
          sqrCategoryBg,
          data[0].participants,
          this.firstFlow.thirdTypeData.netFlowdefCode
        );
        this.cqhyData.participant = this.sqrCategoryBg(
          sqrCategoryBg,
          this.cqhyData.participant,
          this.firstFlow.thirdTypeData.netFlowdefCode
        );
        this.cqhyData.qlxxExMhs = this.getqlxxExMhs(this.cqhyData.qlxxExMhs);
      } else {
        this.checkResult = '未查询到相关产权信息';
        this.$message.error(message);
      }
    },
    //修改参数属性名称
    changeKey(cqzhy, ckey) {
      (cqzhy || []).forEach((data) => {
        for (let key in data) {
          if (key === 'bdcqzh') {
            data['' + ckey] = data.bdcqzh;
          }
        }
      });
    },
    passCertify() {
      this.toRegister();
    },
    async toRegister() {
      /**
       * params 需要提交给后台的数据
       * localDataUser 用户信息
       * firstFlow 选择的申请类型数据
       * firstFlow.secondTypeData 选择的申请类型二级类
       * firstFlow.thirdTypeData 选择的申请类型三级类
       * firstFlow.fourTypeData 选择的申请类型四级类
       * */
      const params = {
        taxStatus: this.taxStatus,
        beianStatus: this.beianStatus,
        sfyddbl: 0,
        userName: localDataUser.get().realName,
        lcdm: this.firstFlow.thirdTypeData.netFlowdefCode,
        userId: localDataUser.get().id, // 用户id
        sqrlx: localDataUser.get().userCategory, // 申请人类型
        bdclb: this.firstFlow.thirdTypeData.netFlowdefCode == 'F400200'? 'NYD' : this.firstFlow.secondTypeData.bdclbEnum, // 不动产类别
        flowcode: this.firstFlow.thirdTypeData.flowCode,
        netFlowdefCode: this.firstFlow.thirdTypeData.netFlowdefCode,
        flowname: this.firstFlow.thirdTypeData.name,
        djdl: this.firstFlow.thirdTypeData.djdl,
        qllx: this.firstFlow.thirdTypeData.qllx,
        subFlowcode: this.firstFlow.fourTypeData.code,
        subFlowname: this.firstFlow.fourTypeData.name,
        taskCode: this.firstFlow.thirdTypeData.taskCode,
        pjlcmc: this.firstFlow.thirdTypeData.pjlcmc,
        taskHandleItem: this.firstFlow.thirdTypeData.taskHandleItem,
        qlxxExMhList: [],
        qlxz: '', // 补充的参数
        ...this.getSelectedOrgInfo,
      };
      const { data, success, message } = await applicantSaveService.saveSqxx(params);
      if (success) {
        await this.$router.push({
          name: this.nextItem.name,
          params: { ywh: data.wwywh },
        });
      } else {
        this.$message.error(message);
      }
    },
    /*
     * 权利扩扩展信息整合
     * @param data 权利信息数据
     * @param subFlowname 流程名称
     */
    arrangeqlxxEx(data, subFlowname) {
      let qlxxEx = {
        sffbcz: false,
        sfdbz: false,
        sqzsbs: '0',
        sqdjyy: subFlowname,
      };
      //申请登记原因
      if (data.qlxxExtra.sqdjyy) {
        qlxxEx = data.qlxxExtra;
        qlxxEx.sffbcz = data.qlxxExtra.sffbcz || false;
        qlxxEx.sfdbz = data.qlxxExtra.sfdbz || false;
        qlxxEx.sqzsbs = data.qlxxExtra.sqzsbs || '0';
        qlxxEx.sqdjyy = data.qlxxExtra.sqdjyy || subFlowname;
      }
      //债务履行时间
      if (data.qlxxExtra.zwlxqssj) {
        qlxxEx.zwlxqssj = formatDate(data.qlxxExtra.zwlxqssj, 'yyyy-MM-dd');
        qlxxEx.zwlxjssj = formatDate(data.qlxxExtra.zwlxjssj, 'yyyy-MM-dd');
        qlxxEx.gfhtqdrq = data.qlxxExtra.gfhtqdrq ? formatDate(data.qlxxExtra.gfhtqdrq, 'yyyy-MM-dd') : '';
        if (!data.qlxxExtra.sqdjyy) {
          qlxxEx.sqdjyy = subFlowname;
        }
      }
      if (data.qlxxExtra.cfqssj) {
        qlxxEx.cfqssj = formatDate(data.qlxxExtra.cfqssj, 'yyyy-MM-dd');
        qlxxEx.cfjssj = formatDate(data.qlxxExtra.cfjssj, 'yyyy-MM-dd');
        if (!data.qlxxExtra.sqdjyy) {
          qlxxEx.sqdjyy = subFlowname;
        }
      }
      if (data.qlxxExtra.ygdjzl) {
        qlxxEx.ygdjzl = '';
      }
      if (data.qlxxExtra.qdjg) {
        data.bdcxx.forEach((item) => {
          item.fwjyjg = data.qlxxExtra.qdjg;
        });
      }
      qlxxEx = Object.assign(data.qlxxExtra, qlxxEx);

      return qlxxEx;
    },
    /*
     * sqrCategoryBg 改变获取的申请人信息的category
     * @param {Oject} sqrCategoryBg category变更对照表
     * @param {Oject} participants 获取到的申请人信息
     * @param {Oject} flowCode 当前选中流程代码
     */
    sqrCategoryBg(sqrCategoryBg, participants, flowCode) {
      let sqrxx = participants || [];
      let bgData = [];
      let newsqrxx = [];
      sqrCategoryBg.forEach((item) => {
        if (item.code === flowCode) {
          bgData = item.bg;
        }
      });
      sqrxx &&
        sqrxx.length > 0 &&
        sqrxx.forEach((item) => {
          bgData.forEach((bg) => {
            if (bg['category' + item.category]) {
              item.category = bg['category' + item.category];
              newsqrxx.push(item);
            }
          });
        });
      return newsqrxx;
    },
    /*
     * uniqueSqrxx 去除掉未配置业务关系的category申请人
     * @param {Oject} participants 获取到的申请人信息
     */
    uniqueSqrxx(sqrCategoryBg, participants, flowCode) {
      let sqrxx = participants || [];
      let bgData = [];
      let newsqrxx = [];
      sqrCategoryBg.forEach((item) => {
        if (item.code === flowCode) {
          bgData = item.bg;
        }
      });
      sqrxx &&
        sqrxx.length > 0 &&
        sqrxx.forEach((item) => {
          bgData.forEach((bg) => {
            if (bg['category' + item.category]) {
              //初始化申请人
              item.qlrlx = item.qlrlx || item.sqrlx;
              item.sfczr = item.sfczr || '1';
              if (item['zjzl'] === '1' && item['zjh']) {
                item['xb'] = this.getXbByidcard(item['zjh']);
              }
              newsqrxx.push(item);
            }
          });
        });
      return newsqrxx;
    },
    getXbByidcard(strCardId) {
      let nSexNum = Number(strCardId.substr(-2, 1));
      if (nSexNum % 2 === 0) {
        return '2';
      } else {
        return '1';
      }
    },
    /*
     * 获取选中户的权利信息
     * @param huArray 选中的户信息
     * @param subFlowname 三级流程名称
     */
    getqlxxExMhs(huArray) {
      (huArray || []).forEach((item, index) => {
        item.fwcqmj = item.jzmj;
      });
      return huArray;
    },
    myFormatDate(time) {
      return formatDate(time, 'yyyy-MM-dd');
    },
    getAllNodes(arrayNodes, attChildren) {
      if (!arrayNodes) {
        return [];
      }
      attChildren = !attChildren ? 'childrens' : attChildren;
      var result = [];
      for (var ii = 0; ii < arrayNodes.length; ii++) {
        var pNode = arrayNodes[ii];
        result.push(pNode);
        if (pNode[attChildren]) {
          var arrayTempNodes = this.getAllNodes(pNode[attChildren], attChildren);
          result = result.concat(arrayTempNodes);
        }
      }
      return result;
    },
    //根据区域名称获取对应code值
    // getCodeByAreaName(name) {
    //   let code = '';
    //   this.allAreaList.find(function(item) {
    //     if (item.title === name) {
    //       code = item.code;
    //     }
    //   });
    //   return code;
    // }
  },
  mounted() {
    // 改变步骤条状态
    if (this.beianStatus === '1') {
      this.currentSelecte.forEach((data, index) => {
        if (data.name === 'authorizedCertify') this.currentSelecte.splice(index, 1);
      });
    }
    this.targetToStep('产权证核验');
  },
};
</script>

<style scoped lang="scss">
@import 'src/styles/common-variables';
@import 'src/styles/public';
.main {
  /deep/ .el-table {
    th {
      background-color: #f6f6f6;
    }
  }
  padding-bottom: 20px;
  padding-top: 20px;
  line-height: 40px;
  letter-spacing: 1px;
  background-color: $background-color-layout;
  .topWrap {
    position: relative;
  }
  .wrap {
    //border: 1px solid #dddddd;
    margin-bottom: 10px;
    .bgnColor {
      //border-top: 1px solid #dddddd;
      background: #f6f6f6;
      padding: 20px 40px;
      .width160 {
        float: left;
        width: 160px;
        text-align: right;
        margin-top: -4px;
      }
    }
    .title {
      font-size: 16px;
      font-weight: bold;
      color: #333333;
      line-height: 30px;
      overflow: hidden;
      padding: 10px;
      border: 3px;
      .btnWrap {
        width: 60px;
        float: right;
        cursor: pointer;

        text-align: center;
        color: #ff5b24;
        font-size: 20px;
      }
      .redPoint {
        color: red;
        font-size: 14px;
        margin-left: 10px;
      }
    }
    .searchBtn {
      text-align: left;
      padding-right: 10px;
      span {
        color: #ff5b24;
      }
      button {
        float: right;
      }
    }
  }
  .table {
    width: 100%;
    border-collapse: collapse;
    td {
      padding: 0 10px;
      font-size: 14px;
      color: #383838;
      border: 0.1px solid #dddddd;
      text-align: left;
    }
    .labelTip {
      width: 170px;
      background: #e5e9f2;
      text-align: right;
    }
  }
}
.tip-title {
  padding: 10px 20px 20px 10px;
  display: flex;
  .right-box {
    margin-top: 10px;
  }

  p {
    line-height: initial;
    letter-spacing: 2px;
  }
}
.warpTips {
  background-color: #fff5eb;
  margin: 0px 20px 20px 20px;
  color: #ff0a0a;
  border-radius: 4px;
  font-size: 14px;
}
</style>
