import request from '@/pages/iebdc/utils/request';
import { REST_SERVICE } from '@/pages/iebdc/config';

const applicantSaveService = {
  // 保存申请信息
  saveSqxx(data) {
    return request({
      url: REST_SERVICE.apply.saveSqxx,
      method: 'post',
      // headers: {
      //   'Content-Type': 'application/json'
      // },
      data,
    });
  },
  // 修改流程信息
  modifyProcessInfo(data) {
    return request({
      url: REST_SERVICE.apply.modifyProcessInfo,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      data,
    });
  },
  // 获取流程信息
  getProcessInfo(params) {
    return request({
      url: REST_SERVICE.apply.getProcessInfo,
      method: 'get',
      params,
    });
  },
  saveUserIdPcbh(params) {
    return request({
      url: REST_SERVICE.apply.saveUserIdPcbh,
      method: 'post',
      params,
    });
  },
  getUserIdPcbh(params) {
    return request({
      url: REST_SERVICE.apply.getUserIdPcbh,
      method: 'post',
      params,
    });
  },
};

export default applicantSaveService;
